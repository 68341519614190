import addDays from "date-fns/addDays";
import differenceInMinutes from "date-fns/differenceInMinutes";
import dateFnsFormat from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isToday from "date-fns/isToday";
import isTomorrow from "date-fns/isTomorrow";
import isAfter from "date-fns/isAfter";
import nb from "date-fns/locale/nb";
import isSameWeek from "date-fns/isSameWeek";
import { norwegianDateToUTC, UTCDateToNorwegian } from "@libry-content/common";

export class DateHelper {
  private date: Date;

  constructor(date: Date | string) {
    this.date = new Date(date);
  }

  /**
   * Takes a timestring on the format HH:mm, interpreted in Norwegian timezone,
   * and converts to a DateHelper where the date is set to Norway's current day
   */
  public static fromTime(time?: string) {
    const now = DateHelper.now();
    if (!time) return new DateHelper(now);

    const [todaysDate] = UTCDateToNorwegian(now).toISOString().split("T");
    const dateTime = norwegianDateToUTC(`${todaysDate}T${time}`);

    return new DateHelper(dateTime);
  }

  public static now() {
    return new Date();
  }

  public static daysFromNow(days: number) {
    return addDays(DateHelper.now(), days);
  }

  public get minutesFromNow() {
    return differenceInMinutes(this.date, DateHelper.now());
  }

  public get formatDistanceToNow() {
    return formatDistanceToNow(this.date, { locale: nb });
  }

  public get isToday() {
    return isToday(this.date);
  }

  public get isTomorrow() {
    return isTomorrow(this.date);
  }

  public get isThisWeek() {
    return isSameWeek(this.date, DateHelper.now(), {
      locale: nb,
    });
  }

  public isBefore(date: Date | DateHelper) {
    return isBefore(this.date, date instanceof DateHelper ? date.date : date);
  }

  public isBeforeNow() {
    return this.isBefore(DateHelper.now());
  }

  public isSameOrBeforeNow() {
    return !this.isAfterNow();
  }

  public isAfter(date: Date | DateHelper) {
    return isAfter(this.date, date instanceof DateHelper ? date.date : date);
  }

  public isAfterNow() {
    return this.isAfter(DateHelper.now());
  }

  public isSameOrAfterNow() {
    return !this.isBeforeNow();
  }

  public isSameDay(date: Date) {
    return isSameDay(this.date, date);
  }

  public get isTodayOrLater() {
    return this.isToday || this.isAfter(DateHelper.now());
  }

  public isSameDateOrBefore(date: Date) {
    return this.isBefore(date) || this.isSameDay(date);
  }

  public isSameDateOrAfter(date: Date) {
    return this.isAfter(date) || this.isSameDay(date);
  }

  public get weekdayLabel() {
    return DateHelper.getLabel(this.date, "eee");
  }

  public static getLabel(date?: string | Date, format?: string) {
    if (!date) return "ukjent dato";
    const helper = new DateHelper(date);
    if (helper.isToday) return "i dag";
    if (helper.isTomorrow) return "i morgen";
    return dateFnsFormat(helper.date, format || "eee d. LLL", { locale: nb });
  }

  public static timeStringDifferenceInMinutes(timeA?: string, timeB?: string): number {
    if (!timeA || !timeB) return NaN;
    return differenceInMinutes(DateHelper.fromTime(timeA).date, DateHelper.fromTime(timeB).date);
  }
}

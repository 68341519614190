import { DecorativeImage, Publication } from "@libry-content/types";
import { screenshotServiceUrl } from "./screenshotService";

export const getBookListImages = (publications: Publication[]) =>
  (
    publications
      ?.map((publication) => publication?.image)
      ?.filter((image): image is DecorativeImage => image !== undefined) || []
  ).slice(0, 3);

const getBookListFanUrl = (publications: Publication[]) => {
  const images = getBookListImages(publications)
    .map((image) => image.asset._ref)
    .join(",");
  return images.length ? `/felles/bokvifte?bilder=${images}` : undefined;
};

export const getBookListOpenGraphImageUrl = (publications: Publication[]) => {
  const url = getBookListFanUrl(publications);
  return url ? screenshotServiceUrl(url, { left: 0, top: 100, width: 600, height: 314 }) : undefined;
};

import { withErrorBoundary } from "@biblioteksentralen/js-utils";
import { libryContentUrl, getSiteUrl, withoutUndefinedValues } from "@libry-content/common";
import { ts } from "@libry-content/localization";
import { DecorativeImage, ImageWithMetadata, Site } from "@libry-content/types";
import Head from "next/head";
import React, { useEffect } from "react";
import type { Thing } from "schema-dts";
import { loggError } from "../utils/logging";
import { imageUrlBuilder } from "../utils/sanity/client";
import { useSiteContext } from "./layout/SiteContext";
import { getSchemaOrgImageUrl } from "./library/utils";
import { ResolvedSite } from "./site/sanityQuery";
import { getSchemaOrgSiteImage } from "./site/utils";

interface Props {
  title: string;
  description: string;
  path: string;
  externalImageUrl?: string;
  sanityImage?: DecorativeImage | ImageWithMetadata;
  icon?: string;
  schemaOrgThing?: Omit<Thing, string>;
}

export function getTitle(title: string, site?: Pick<Site, "name">): string {
  if (!site) return title;
  const siteName = ts(site.name) ?? "";
  if (!title.length) return siteName;
  if (title === siteName) return title;
  return `${title} | ${siteName}`;
}

function SEO(props: Props) {
  const { site } = useSiteContext();

  const siteImg = getSchemaOrgSiteImage(site);
  const iconUrl = siteImg ? getSchemaOrgImageUrl(siteImg) : undefined;

  const siteImageUrl = props.externalImageUrl ?? getImageUrl(props.sanityImage, siteImg);

  const schemaOrgData = props.schemaOrgThing
    ? {
        "@context": "https://schema.org/",
        name: props.title,
        description: props.description,
        image: siteImageUrl,
        ...withoutUndefinedValues(props.schemaOrgThing),
      }
    : undefined;

  useEffect(() => {
    if (!/^\//.test(props.path)) {
      loggError(new Error("path må starte med /"));
    }
  }, [props.path]);

  const canonical = `${site ? getSiteUrl(site) : libryContentUrl}${props.path}`;
  const title = getTitle(props.title, site);
  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" type="image/png" href={props.icon ?? iconUrl} />
      <link rel="canonical" href={canonical} />
      <meta name="description" content={props.description} />
      <meta property="image" content={siteImageUrl} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonical} />
      <meta property="og:description" content={props.description} />
      <meta property="og:site_name" content={ts(site?.name)} />
      <meta property="og:image" content={siteImageUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={props.description} />
      <meta name="twitter:image" content={siteImageUrl} />

      {schemaOrgData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrgData, null, 2) }}
        />
      )}
    </Head>
  );
}

function getImageUrl(image: Props["sanityImage"], fallbackImage?: ResolvedSite["logo"] | ResolvedSite["coatOfArms"]) {
  if (image?.asset) {
    return imageUrlBuilder(image)?.size(1200, 630).format("jpg").quality(85).url() ?? "";
  }

  // Fallback-image using logo or coatOfArms, but padded so it can handle some cropping
  return (
    imageUrlBuilder(fallbackImage)
      ?.ignoreImageParams()
      .size(1200, 630)
      .format("jpg")
      .quality(85)
      .fit("fill")
      .pad(60)
      .bg("eee")
      .url() ?? ""
  );
}

export default withErrorBoundary(SEO, "SEO");

import { sanityConfig } from "./client";
import { getPlatformUrl } from "./platform";

// See https://github.com/biblioteksentralen/screenshot-service
const screenshotServiceBaseUrl =
  process.env.SANITY_STUDIO_SCREENSHOT_SERVICE_URL ?? "https://screenshot-opal.vercel.app/api/screenshot";

const screenshotServiceTargetHost = process.env.SANITY_STUDIO_SCREENSHOT_SERVICE_TARGET_HOST ?? getPlatformUrl();

export type ScreenshotArea = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export const screenshotServiceUrl = (path: string, area: ScreenshotArea) => {
  return `${screenshotServiceBaseUrl}?ds=${sanityConfig.dataset}&url=${encodeURIComponent(
    screenshotServiceTargetHost + path
  )}&area=${area.left},${area.top},${area.width},${area.height}`;
};
